import validator from 'jquery-validation';

export default function () {
  var validator = $('#registrationform').validate({
    rules: {
      firstName: {
        required: true,
        minlength: 2,
      },
      lastName: {
        required: true,
        minlength: 2,
      },
      email: {
        required: true,
        email: true,
      },
      phone: {
        required: true,
        minlength: 5,
      },
      street: {
        required: true,
        minlength: 2,
      },
      streetNumber: {
        required: true,
      },
      postcode: {
        required: true,
        digits: true,
        minlength: 3,
        maxlength: 5,
      },
      city: {
        required: true,
        minlength: 2,
      },
    },

    submitHandler: function () {
      var form = $('#registrationform');
      var dataString = form.serialize();
      //var formAction = form.attr('action');
      var loadingOverlay = $('#registrationform .loading-overlay');
      var loading = $('#registrationform .submit svg');
      var loadingText = $('#registrationform .submit span');

      loadingOverlay.toggleClass('hidden');
      loading.removeClass('hidden');
      loadingText.addClass('hidden');

      $.ajax(
        {
          type: 'POST',
          url: 'https://nan.proderm-studien.de/api/register',
          data: dataString,
          success: function (data) {
            console.log(data);
            setTimeout(() => {
              $('#registrationform').trigger('reset');
              $('#registrationform').addClass('hidden');
              $('#form-success').removeClass('hidden');
              loadingOverlay.toggleClass('hidden');
              loading.addClass('hidden');
              loadingText.removeClass('hidden');
              $('html, body').animate(
                {
                  scrollTop: $('#form-success').offset().top,
                },
                250
              );
            }, 500);
          },
          error: function (data) {
            const { error } = $.parseJSON(data.responseText);
            emailError(error);
            setTimeout(() => {
              $('html, body').animate(
                {
                  scrollTop: $('input#email').offset().top - 150,
                },
                250
              );
              loadingOverlay.toggleClass('hidden');
              loading.addClass('hidden');
              loadingText.removeClass('hidden');
            }, 500);
          },
        },
        'json'
      );
    },
  });

  function emailError (errors) {
    validator.showErrors({
      email: 'Email Adresse bereits vergeben',
    });

    $.each(errors, function (message) {
      console.log(message[0]);
    });

    console.log(errors);
  }

  function generateYears () {
    var minOffset = 18,
      maxOffset = 100;

    var thisYear = new Date().getFullYear();
    var select = $(
      '<select id="birthday-year" name="birthday_year">'
    );

    for (var i = minOffset; i <= maxOffset; i++) {
      var year = thisYear - i;
      $('<option>', { value: year, text: year }).appendTo(select);
    }

    select.appendTo('#birthday-year');
  }

  generateYears();
}

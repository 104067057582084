<template>
  <div class="container px-6">
    <h2 class="text-3xl mb-12 border-b-4 inline-block border-blue-light">Glossar</h2>

    <nav class="AlphabetNav">
      <a href="#A">A</a>
      <a href="#B">B</a>
      <a href="#C">C</a>
      <a href="#D">D</a>
      <a href="#E">E</a>
      <a href="#F">F</a>
      <a href="#G">G</a>
      <a href="#H">H</a>
      <a href="#I">I</a>
      <a href="#J">J</a>
      <a href="#K">K</a>
      <a href="#L">L</a>
      <a href="#M">M</a>
      <a href="#N">N</a>
      <a href="#O">O</a>
      <a href="#P">P</a>
      <a href="#Q">Q</a>
      <a href="#R">R</a>
      <a href="#S">S</a>
      <a href="#T">T</a>
      <a href="#U">U</a>
      <a href="#V">V</a>
      <a href="#W">W</a>
      <a href="#X">X</a>
      <a href="#Y">Y</a>
      <a href="#Z">Z</a>
    </nav>

    <details class="mb-5 Contact" v-for="item in groupedEntries" :key="item.id">
      <summary class="font-normal text-lg text-blue">
        <span class="title">{{ item.title }}</span>
        <hr class="w-full h-1 text-gray-1000 my-4" style="opacity: .15" />
      </summary>
      <div class="text-gray-800">{{ item.text }}</div>
    </details>
  </div>
</template>
<script>
import _ from "lodash";

const alphabet = [..."abcdefghijklmnopqrstuvwxyz"];

export default {
  data() {
    return {
      searchQuery: "",
      entries: {}
    };
  },
  computed: {
    groupedEntries() {
      let addedFirstLetter = _(this.entries).each(item => {
        _.merge(item, { firstLetter: item.title.charAt(0).toUpperCase() });
      });
      let groupedByFirstLetter = _.groupBy(addedFirstLetter, "firstLetter");
      return groupedByFirstLetter;
    },

    isEmpty() {
      return this.$eval("groupedEntries | filterBy searchQuery");
    }
  },
  mounted() {
    this.$api({
      method: "post",
      data: {
        query: `
              query getGlossary {
                entries(section:["glossary"]) { 
                  id,
                  title,
                  ... on glossary_glossar_Entry {
                    text
                  }
                }
              }
            `
      }
    }).then(result => {
      const {
        data: { entries }
      } = result.data;
      this.entries = entries;
    });
  },
  methods: {
    size(value) {
      return _.size(value);
    }
  }
};
</script>
export default function toggleProfileForm() {
    document.getElementById('toggleProfileForm').onclick = function() {
        const toggleProfileFormText = document.getElementById('toggleProfileForm');
        
        const form = document.getElementById("profile-form");
        form.classList.toggle("hidden");
        
        if (toggleProfileFormText.innerHTML === "Bearbeiten") {
            toggleProfileFormText.innerHTML = "Abbrechen";
        } else {
            toggleProfileFormText.innerHTML = "Bearbeiten";
        }
        
        const profile = document.getElementById("personal-profile");
        profile.classList.toggle("hidden");
    }
}
import toggleProfileForm from './modules/profile';
import registration from './modules/registration';
import Glide from '@glidejs/glide';
import Vue from 'vue/dist/vue.js';
import vueCustomElement from 'vue-custom-element';
import glossaryApp from './modules/glossary';
import axios from 'axios'
import jquery from 'jquery'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import 'swiper/swiper-bundle.min.css'
import Swiper from 'swiper/swiper-bundle.min'

global.$ = global.jQuery = jquery;

registration();

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(vueCustomElement);

Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: 'https://dev.proderm-studien.de/api'
    })
  }
})

Vue.customElement('glossary-app', glossaryApp);

$('#menu-trigger').on('click', function (e) {
  var html = $('html');
  var menu = $('#menu-main');

  $(this).toggleClass('is-active');
  html.toggleClass('overflow-y-hidden');
  menu.toggle('slow');
})

// if ($(window).width() < 1024) {
//   // if ($("body").hasClass("homepage")) {
//   //   $('header').addClass('fixed-header has-shadow bg-white');
//   // }
//
//   $('.menu-hasDropdown-trigger').on('click', function (e) {
//     console.log($(this).children());
//     $(this).closest('.has-descendants').children('.dropdown-mobile').slideToggle('slow');
//   })
//
//   $('.dropdown-mobile').removeClass('dropdown');
//
//   ssm.addState({
//     id: 'desktop',
//     query: '(min-width: 1024px)',
//     onEnter: function () {
//       console.log('enter desktop');
//       $('#menu-main').css('display', '');
//       $('.dropdown-mobile').css('display', '');
//       $('.dropdown-mobile').addClass('dropdown');
//       $('#menu-trigger').removeClass('is-active');
//       $('html').removeClass('overflow-y-hidden');
//
//       if ($("body").hasClass("homepage")) {
//         $('header').removeClass('fixed-header has-shadow bg-white');
//       }
//       ;
//     },
//     onLeave: function () {
//       console.log('leave desktop');
//       $('.dropdown-mobile').removeClass('dropdown');
//       if ($("body").hasClass("homepage")) {
//         $('header').addClass('fixed-header has-shadow bg-white');
//       }
//     }
//   });
// } else {
//   ssm.addState({
//     id: 'mobile',
//     query: '(max-width: 1024px)',
//     onEnter: function () {
//       console.log('enter mobile');
//       $('.dropdown-mobile').css('display', '');
//       $('.dropdown-mobile').removeClass('dropdown');
//       $('.menu-hasDropdown-trigger').on('click', function (e) {
//         console.log($(this).children());
//         $(this).closest('.has-descendants').children('.dropdown-mobile').slideToggle('slow');
//       });
//
//       // if ($("body").hasClass("homepage")) {
//       //   $('header').addClass('fixed-header has-shadow bg-white');
//       // };
//     },
//     onLeave: function () {
//       console.log('leave mobile');
//       $('.dropdown-mobile').addClass('dropdown');
//       $('#menu-main').css('display', '');
//       $('.dropdown-mobile').css('display', '');
//       $('#menu-trigger').removeClass('is-active');
//       $('html').removeClass('overflow-y-hidden');
//
//       // if ($("body").hasClass("homepage")) {
//       //   $('header').removeClass('fixed-header has-shadow bg-white');
//       // };
//     }
//   });
// }

//

// if ($("body").hasClass("homepage")) {
//   $("header.header").hover(
//     function () {
//       $(this).addClass("has-shadow bg-white");
//     }, function () {
//       if (!$(this).hasClass('fixed-header')) {
//         $(this).removeClass("has-shadow bg-white");
//       }
//     }
//   );
// }

$(".AlphabetNav a").addClass('text-lg text-semibold mr-5')

$(".AlphabetNav a").click(function (evt) {
  evt.preventDefault();

  var $navItem = $(this),
    $glossary = $(".glossary");

  const clickedValue = $("h3.clicked").html($navItem.text());

  $glossary.show();

  if ($navItem.hasClass("text-blue")) {
    $navItem.removeClass("text-blue border-b-2 pb-1");
  } else {
    $(".AlphabetNav a").removeClass("text-blue border-b-2 pb-1");
    $navItem.addClass("text-blue border-b-2 pb-1");

    $.each($glossary, function (key, title) {
      var $title = $(title),
        $titleName = $title.find(".title"),
        $nameArr = $titleName.text().split(" ");

      console.log($nameArr[0].split("")[0].toLowerCase());

      if (
        $nameArr[0].split("")[0].toLowerCase() !== $navItem.text().toLowerCase()
      ) {
        $title.hide();
      }
    });
  }
});

if ($('.quote-slider').length) {
  let quoteSlider = document.getElementsByClassName('quote-slider');
  quoteSlider = new Glide('.quote-slider', {
    type: 'slider',
    perView: 1,
    gap: 30,
    focusAt: 'center',
  })
  quoteSlider.mount();
}

if ($('.card-slider').length) {
  let cardSlider = document.getElementsByClassName('card-slider');
  cardSlider = new Glide('.card-slider', {
    type: 'carousel',
    perView: 3,
    breakpoints: {
      1024: {
        perView: 2
      },
      580: {
        perView: 1
      }
    }
  })
  cardSlider.mount();
}

if ($('.team-slider').length) {
  let teamSlider = document.getElementsByClassName('.team-slider');
  teamSlider = new Glide('.team-slider', {
    type: 'carousel',
    perView: 4,
    breakpoints: {
      1024: {
        perView: 2
      },
      580: {
        perView: 1
      }
    }
  })
  teamSlider.mount();
}

if ($('.partner-slider').length) {
  let partnerSlider = document.getElementsByClassName('.partner-slider');
  partnerSlider = new Glide('.partner-slider', {
    type: 'carousel',
    perView: 5,
    breakpoints: {
      1024: {
        perView: 3
      },
      580: {
        perView: 2
      }
    }
  })
  partnerSlider.mount();
}

if ($('#profile-form').length) {
  toggleProfileForm();
}

if ($('.error-list').length) {
  toggleProfileForm();
}

if ($('#map').length) {
  mapboxgl.accessToken = 'pk.eyJ1IjoiZnJpZW5kdmVudHVyZSIsImEiOiJjazM0azNyYjAwMngwM21tdnBpMHJuZWJtIn0.1-jc6ENzOH1O4Ogm9r3vYQ';

  let mapData = $('#map').data('locations');
  let mapZoom = $('#map').data('zoom');
  console.log(mapData);

  var geojson = {
    "type": "FeatureCollection",
    "features": [
      mapData[0],
      mapData[1]
    ]
  };

  console.log(mapData[0].geometry.coordinates[0], mapData[0].geometry.coordinates[1]);

  var map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/mapbox/light-v10',
    center: [mapData[0].geometry.coordinates[0], mapData[0].geometry.coordinates[1]],
    zoom: mapZoom,
    attributionControl: true
  });

  map.addControl(new mapboxgl.AttributionControl(), 'top-right');
  map.addControl(new mapboxgl.NavigationControl());

  map.scrollZoom.disable();
  // add markers to map
  geojson.features.forEach(function (marker) {

    // create a HTML element for each feature
    var el = document.createElement('div');
    el.className = 'marker';

    // make a marker for each feature and add it to the map
    new mapboxgl.Marker(el)
    .setLngLat({
      'lng': marker.geometry.coordinates[0],
      'lat': marker.geometry.coordinates[1]
    })
    .setPopup(new mapboxgl.Popup({ offset: 25, closeOnClick: false, closeButton: false }) // add popups
    .setHTML('<h3 class="">' + marker.properties.title + '</h3><p class="">' + marker.properties.street + ' ' + marker.properties.number + '</p>' + '<p class="">' + marker.properties.city + '</p>'))
    .addTo(map)
    /*.togglePopup()*/;
  });
}

//cardSlider.mount();
//quoteSlider.mount();

$(document).ready(function () {
  new Swiper('[is="block-study-slider"] .swiper-container', {
    loop: true,
    navigation: {
      nextEl: '[is="block-study-slider"] .slider-button--next',
      prevEl: '[is="block-study-slider"] .slider-button--prev'
    },
    slidesPerView: 1,
    pagination: {
      el: '[is="block-study-slider"] [data-slider-pagination]',
      clickable: true
    },
    spaceBetween: 20,
    breakpoints: {
      1028: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 10
      }
    }
  });
});

$(document).ready(function () {
  new Swiper('[is="block-quotes-slider"] .swiper-container', {
    loop: true,
    navigation: {
      nextEl: '[is="block-quotes-slider"] .slider-button--next',
      prevEl: '[is="block-quotes-slider"] .slider-button--prev'
    },
    slidesPerView: 1,
    pagination: {
      el: '[is="block-quotes-slider"] [data-slider-pagination]',
      clickable: true
    },
    spaceBetween: 20,
    autoHeight: true
  });
});
$(document).ready(function () {
  new Swiper('[is="block-aerzte-team"] .swiper-container', {
    loop: true,
    navigation: {
      nextEl: '[is="block-aerzte-team"] .slider-button--next',
      prevEl: '[is="block-aerzte-team"] .slider-button--prev'
    },
    slidesPerView: '1',
    pagination: {
      el: '[is="block-aerzte-team"] [data-slider-pagination]',
      clickable: true
    },
    spaceBetween: 20,
    autoHeight: true,
    breakpoints: {
      1028: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 10
      }
    }
  });
});

$(document).ready(function () {
  $("[is='basis-header'] .menu-hasDropdown-trigger").click(function () {
    console.log(this)

    if ($(this).next('.menu-isDropdown').hasClass('isActive')) {
      $(this).next('.menu-isDropdown').removeClass('isActive');
    } else {
      $('.menu-isDropdown').removeClass('isActive');
      $(this).next('.menu-isDropdown').addClass('isActive');
    }
  });

  $(window).scroll(function () {
    $('.menu-isDropdown').removeClass('isActive');
  });
});

$(document).ready(function () {
  $("[is='basis-header'] .hamburger").click(function () {

    if ($("[is='basis-header'] .menu-wrapper--mobile").hasClass('isActive')) {
      $("[is='basis-header'] .menu-wrapper--mobile").removeClass('isActive');
    } else {
      $("[is='basis-header'] .menu-wrapper--mobile").addClass('isActive');
    }
  });
});
